import { render, staticRenderFns } from "./NuevoPricing.vue?vue&type=template&id=42a138a8&scoped=true"
import script from "./NuevoPricing.vue?vue&type=script&lang=js"
export * from "./NuevoPricing.vue?vue&type=script&lang=js"
import style0 from "./NuevoPricing.vue?vue&type=style&index=0&id=42a138a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a138a8",
  null
  
)

export default component.exports