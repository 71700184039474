<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12" md="4">
          <span class="d-flex">
            <h1 class="primary--text" style="white-space: nowrap;">DATOS</h1>
            <v-spacer></v-spacer>
            <v-btn color="info" @click.stop="vincular"><v-icon>mdi-sync</v-icon></v-btn>
          </span>
          <v-divider></v-divider>
          <v-text-field
            filled
            hide-details="auto"
            v-model="pricing.clientes"
            label="Nombre Cliente/s"
            append-icon="mdi-account-multiple"
            :rules="[rules.req]"
          ></v-text-field>
          <v-menu ref="menu" v-model="menu" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field label="Fecha Evento" clearable :value='pricing.fechaEv != null ? ((new Date(pricing.fechaEv).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(pricing.fechaEv).getDate()).slice(-2)) + (new Date(pricing.fechaEv).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(pricing.fechaEv).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker @click:date="menu = false" no-title v-model="pricing.fechaEv" :first-day-of-week="1" scrollable>
            </v-date-picker>
          </v-menu>
          <v-select
            :items="espacios"
            :item-text="item => item.nombreEspacio"
            :item-value="item => item.idEspacio"
            filled
            hide-details="auto"
            v-model="pricing.idEspacio"
            label="Espacio"
            append-icon="mdi-home-group"
            clearable
          ></v-select>
          <v-text-field
            filled
            hide-details="auto"
            v-model="pricing.personas"
            label="Personas"
            append-icon="mdi-human-male"
          ></v-text-field>
          <v-select
            filled
            hide-details="auto"
            v-model="pricing.tipoPpto"
            label="Tipo Presupuesto"
            append-icon="mdi-file-sign"
            :items="tipoPpto"
            :item-text="item => item.title"
            :item-value="item => item.val"
          ></v-select>
          <v-menu ref="menu" v-model="menu2" :return-value.sync="date2" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field label="Fecha Ppto" clearable :value='pricing.fecha != null ? ((new Date(pricing.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(pricing.fecha).getDate()).slice(-2)) + (new Date(pricing.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(pricing.fecha).getFullYear()) : ((new Date().toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date().getDate()).slice(-2)) + (new Date().toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date().getFullYear())' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker @click:date="menu2 = false" no-title v-model="pricing.fecha" :first-day-of-week="1" scrollable>
            </v-date-picker>
          </v-menu>
          <v-select
            :items="cartas.filter(c => pricing.idEspacio ? c.idEspacio == pricing.idEspacio : true)"
            :item-text="item => (espacios.find(e => e.idEspacio == item.idEspacio)?.nombreEspacio || 'MISSING NAME') + ' ' + item.nombre + ' ' + item.year"
            :item-value="item => item.idCarta"
            filled
            hide-details="auto"
            v-model="pricing.idCarta"
            label="Carta base"
            append-icon="mdi-file-document"
            :rules="[rules.req]"
          >
          </v-select>
          <v-textarea
            filled
            hide-details="auto"
            v-model="pricing.notas"
            label="Notas"
            append-icon="mdi-note-edit"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="6">
              <h1 class="primary--text">PRICING (Carta: +{{ parseFloat(cartas.find(c => c.idCarta == pricing.idCarta)?.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €)</h1>
              <v-divider class="mb-2"></v-divider>
              <template v-for="tipo in tiposModifiacion">
                <div class="d-flex flex-row">
                  <h3 style="max-width: min-content;">{{ tipo.nombre }}</h3>
                  <v-spacer></v-spacer>
                  <v-menu 
                    offset-x
                    :close-on-click="true"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="z-index:0"
                        color="primary"
                        outlined
                        small
                        icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span class="d-flex">
                      <v-select :items="familias" :item-text="item => item.title" :item-value="item => item.val" 
                        class="v-superdense-input pa-0"
                        hide-details=""
                        v-model="searchFamilia"
                        :menu-props="{ bottom: true, offsetY: true }"
                        append-icon="mdi-graph"
                        filled
                        clearable
                        placeholder="Familia"
                        :style="'max-width: 50%;z-index: 10000; border-right: 1px solid var(--v-primary-base); background-color: ' + ($vuetify.theme.dark ? 'var(--v-secondary-base);' : '#fff')"
                        ></v-select>
                      <v-text-field 
                        class="v-superdense-input pa-0"
                        hide-details=""
                        v-model="search"
                        append-icon="mdi-magnify"
                        placeholder="Nombre"
                        filled
                        :style="'max-width: 50%;z-index: 10000; background-color: ' + ($vuetify.theme.dark ? 'var(--v-secondary-base);' : '#fff')"
                      ></v-text-field>
                    </span>
                    <v-list style="max-height: 600px !important;overflow: auto;">
                      <v-list-item
                      class="pa-2"
                        :style="'background-color: ' + ($vuetify.theme.dark ? 'var(--v-secondary-base);' : '#fff')"
                      >
                        <v-btn color="primary" @click="addModCustom(tipo.val)" style="width:100%;height:100%;" class="py-5">EDITABLE <v-icon>mdi-plus</v-icon></v-btn>
                      </v-list-item>
                      <v-list-item
                        :style="'background-color: ' + ($vuetify.theme.dark ? 'var(--v-secondary-base);' : '#fff')"
                        v-for="(item, index) in modificaciones.filter(em => (em.nombre).toLowerCase().indexOf((search || '').toLowerCase()) > -1 && (searchFamilia ? (em.familia == searchFamilia) : true))"
                        :key="index"
                        @click.stop="addMod(tipo.val,item)"
                      >
                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <template v-if="(pricing.modificaciones && pricing.modificaciones.length) || (pricing.modificacionesCustom && pricing.modificacionesCustom.length)">
                  <template v-for="mod,i in pricing.modificaciones.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;">
                      <v-col cols="7" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)">{{ modificaciones.find(r => r.idItem == mod.idItem)?.nombre }}</div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center"><v-text-field placeholder="UDS" type="number" @focus="$event.target.select()" dense class="num v-superdense-input" hide-details="" v-model="mod.uds" @input="$forceUpdate()"></v-text-field></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat( (tipo.val == 1) ? ( modificaciones.find(r => r.idItem == mod.idItem)?.add || 0 ) : ((tipo.val == 2) ? ( modificaciones.find(r => r.idItem == mod.idItem)?.remove || 0 ) : ((tipo.val == 3) ? ( modificaciones.find(r => r.idItem == mod.idItem)?.replace || 0 ) : 0))).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0 justify-center text-center"><v-btn style="height: min-content !important;" icon color="error" @click="removeModificacion(tipo.val,mod.idItem)"><v-icon>mdi-close</v-icon></v-btn></v-col>
                    </div>
                  </template>
                  <template v-for="mod,i in pricing.modificacionesCustom.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;">
                      <v-col cols="7" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)"><v-text-field v-model="mod.nombre" placeholder="Nombre" @focus="$event.target.select()" dense class="v-superdense-input short-input blue-input" hide-details=""></v-text-field></div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center"><v-text-field placeholder="UDS" type="number" @focus="$event.target.select()" dense class="num v-superdense-input short-input" hide-details="" v-model="mod.uds" @input="$forceUpdate()"></v-text-field></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field v-model="mod.precio" placeholder="Precio" type="number" @focus="$event.target.select()" dense class="num v-superdense-input ended-input short-input" hide-details="" @input="$forceUpdate()"></v-text-field> €</div></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0 justify-center text-center"><v-btn style="height: min-content !important;" icon color="error" @click="removeModificacionCustom(tipo.val,mod.idItem)"><v-icon>mdi-close</v-icon></v-btn></v-col>
                    </div>
                  </template>
                </template>
                <v-divider class="my-2"></v-divider>
              </template>
              <v-row>
                <v-col cols="12">
                  <v-text-field style="align-self: baseline;" dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasModificaciones"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <h1 class="primary--text">TARIFAS</h1>
              <v-divider class="mb-2"></v-divider>
              <v-row class="justify-space-between align-center mt-0 mx-n1" :style="'border-bottom: 2px solid ' + ($vuetify.theme.dark ? 'var(--v-primary-base)' : 'black')">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1" style="font-size:x-large;"><h3>Precio Pricing</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right primary--text" style="font-size:x-large;">{{ prTotal().toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €" }}</v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1" :style="'border-bottom: 2px solid ' + ($vuetify.theme.dark ? 'var(--v-primary-base)' : 'black')">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1" style="font-size:x-large;"><h3>Precio Enviado</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right primary--text"><div style="font-size:x-large;width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field :value="pricing.pDef" placeholder="Precio" @focus="$event.target.select()" dense class="v-superdense-input ended-input short-input" hide-details="" @input="changeDef($event)"></v-text-field> €</div></v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1 mt-4">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1"><h3>Diferencia (€)</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat(pricing.pDef ? (pricing.pDef-prTotal()) : 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1" :style="'border-bottom: 2px solid ' + ($vuetify.theme.dark ? 'var(--v-primary-base)' : 'black')">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1"><h3>Diferencia (%)</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field :value="pricing.pntDif" placeholder="Porcentaje" @focus="$event.target.select()" dense class="v-superdense-input ended-input short-input mb-2" hide-details="" @input="changeDif($event)"></v-text-field> %</div></v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1" style="font-size:x-large;"><h3>Total desc/inc</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right primary--text" style="font-size:x-large;"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ (parseFloat(pricing.pDef ? (pricing.pDef-prTotal()) : 0) * (pricing.personas || 0)).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
              </v-row>
              <v-divider class="mt-3 mb-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasResult"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-1"></v-divider>
          <v-row>
            <v-col cols="12" md="6">
              <h1 class="primary--text">CATERING & EXTRAS</h1>
              <v-divider class="mb-2"></v-divider>
              <template v-for="tipo in tiposCatering">
                <div class="d-flex flex-row">
                  <h3 style="max-width: min-content;">{{ tipo.nombre }}</h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    style="z-index:0"
                    color="primary"
                    outlined
                    small
                    icon
                    dark
                    @click.stop="addCat(tipo.val)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <template v-if="pricing.catering && pricing.catering.length">
                  <template v-for="mod,i in pricing.catering.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;" class="mb-2">
                      <v-col cols="7" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)"><v-text-field v-model="mod.nombre" placeholder="Nombre" @focus="$event.target.select()" dense class="v-superdense-input short-input blue-input" hide-details=""></v-text-field></div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center"><v-text-field placeholder="UDS" type="number" @focus="$event.target.select()" dense class="num v-superdense-input short-input" hide-details="" v-model="mod.uds" @input="$forceUpdate()"></v-text-field></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field v-model="mod.precio" placeholder="Precio" type="number" @focus="$event.target.select()" dense class="num v-superdense-input ended-input short-input" hide-details="" @input="$forceUpdate()"></v-text-field> €</div></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0 justify-center text-center"><v-btn style="height: min-content !important;" icon color="error" @click="removeCatering(tipo.val,mod.idItem)"><v-icon>mdi-close</v-icon></v-btn></v-col>
                    </div>
                  </template>
                </template>
                <v-divider class="my-2"></v-divider>
              </template>
              <v-text-field dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasCatering"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <h1 class="primary--text">CATERING</h1>
              <v-divider class="mb-2"></v-divider>
              <v-row class="justify-space-between align-center">
                <v-col cols="8" class="font-weight-bold primary--text pb-0"><h3>Total Catering</h3></v-col>
                <v-col cols="4" class="font-weight-bold pb-0 text-right">{{ prCat().toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €" }}</v-col>
              </v-row>
              <v-divider class="mt-3 mb-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasResultCat"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-1"></v-divider>
          <v-row>            
            <v-col cols="12" md="6">
              <h1 class="primary--text">ATENCIONES</h1>
              <v-divider class="mb-2"></v-divider>
              <template v-for="tipo in tiposAtenciones">
                <div class="d-flex flex-row">
                  <h3 style="max-width: min-content;">{{ tipo.nombre }}</h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    style="z-index:0"
                    color="primary"
                    outlined
                    small
                    icon
                    dark
                    @click.stop="addCat(tipo.val)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <template v-if="pricing.catering && pricing.catering.length">
                  <template v-for="mod,i in pricing.catering.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;" class="mb-2">
                      <v-col cols="7" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)"><v-text-field v-model="mod.nombre" placeholder="Nombre" @focus="$event.target.select()" dense class="v-superdense-input short-input blue-input" hide-details=""></v-text-field></div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center"><v-text-field placeholder="UDS" type="number" @focus="$event.target.select()" dense class="num v-superdense-input short-input" hide-details="" v-model="mod.uds" @input="$forceUpdate()"></v-text-field></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field v-model="mod.precio" placeholder="Precio" type="number" @focus="$event.target.select()" dense class="num v-superdense-input ended-input short-input" hide-details="" @input="$forceUpdate()"></v-text-field> €</div></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0 justify-center text-center"><v-btn style="height: min-content !important;" icon color="error" @click="removeCatering(tipo.val,mod.idItem)"><v-icon>mdi-close</v-icon></v-btn></v-col>
                    </div>
                  </template>
                </template>
                <v-divider class="my-2"></v-divider>
              </template>
              <v-text-field filled dense class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasAtenciones"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <h1 class="primary--text">ATENCIONES</h1>
              <v-divider class="mb-2"></v-divider>
              <v-row class="justify-space-between align-center">
                <v-col cols="8" class="font-weight-bold primary--text pb-0"><h3>Total Atenciones</h3></v-col>
                <v-col cols="4" class="font-weight-bold pb-0 text-right">{{ prAtenciones().toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €" }}</v-col>
              </v-row>
              <v-divider class="mt-3 mb-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasResultAtenciones"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
            <v-spacer></v-spacer>
						<v-btn @click.stop="cancel()">
							<v-icon left>mdi-close</v-icon>
                Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ pricing.idPricing ? "Crear revisión" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>
    <VincularBoda ref="vincular"></VincularBoda>
  </v-card>
</template>
<script>
import { req, isNumber } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
    espacios: Array,
    cartas: Array,
    familias: Array,
    modificaciones: Array,
    tipo: Number,
    tipoPpto: Array,
    anteriores: Array
  },
  components:{
    VincularBoda: () => import("./VincularBoda.vue"),
  },
  data() {
    return {
      revisiones:[],
      menu: false,
      menu2: false,
      search: "",
      searchFamilia: "",
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      tiposModifiacion: [
        { nombre: "Añadir", val : 1 },
        { nombre: "Quitar", val : 2 },
        { nombre: "Sustituir", val : 3 },
      ],
      tiposCatering: [
        { nombre: "Suplementos", val : 4 },
        { nombre: "Descuentos", val : 5 },
        // { nombre: "Sustituir", val : 6 },
      ],
      tiposAtenciones: [
        { nombre: "Descuentos", val : 7 },
      ],
      default: {
        nombre : null,
      },
      pricing: {
        ...this.value,
      },
      rules: {
        req,
        isNumber,
      },
    };
  },
  methods: {
    async vincular(){
      const boda = await this.$refs.vincular.open();
      if (!boda) return;
      this.$set(this.pricing, 'clientes', boda.nombrePareja)
      this.$set(this.pricing, 'fechaEv', boda.diaReal)
      this.$set(this.pricing, 'personas', ((boda.adultos || 0)+(boda.ninos || 0)))
      this.$set(this.pricing, 'notas', (boda.breveDato || ''))
      if (boda.espacio.split(" ")[0] == "CATERING")
      this.$set(this.pricing, 'espacio', 6)
      else if (boda.espacio == "PISCIS" || boda.espacio == "HOTEL")
      this.$set(this.pricing, 'espacio', 3)
      else if (boda.espacio == "LALANNE")
      this.$set(this.pricing, 'espacio', 4)
      else if (boda.espacio == "LAUS")
      this.$set(this.pricing, 'espacio', 5)
      else if (boda.espacio == "MAS DE PONENT")
      this.$set(this.pricing, 'espacio', 7)
      else if (boda.espacio == "PALACIO")
      this.$set(this.pricing, 'espacio', 8)
    },
    cancel(){
      this.pricing = null
      this.$emit('cancel')
    },
    changeDif(p){
      this.pricing.pntDif = p
      if (p == "-") return
      if (!p) this.pricing.pDef = ""
      else this.pricing.pDef = (parseFloat(this.prTotal() || 0) + parseFloat((p/100) * (this.prTotal() || 0.00))).toFixed(2)
      this.$forceUpdate()
    },
    changeDef(p){
      this.pricing.pDef = p
      if (p == "-") return
      if (!p) this.pricing.pntDif = ""
      else this.pricing.pntDif = ((parseFloat(this.pricing.pDef ? ((this.prTotal()-this.pricing.pDef)/(this.prTotal() || 1)) : 0) * 100)*-1).toFixed(2)
      this.$forceUpdate()
    },
    prTotal(){
      var tot = (this.cartas.find(c => c.idCarta == this.pricing.idCarta)?.precio || 0)
      if (!this.pricing.modificaciones) this.pricing.modificaciones = []
      this.pricing.modificaciones.forEach(mod => {
        var add = 0
        if (mod.tipoModificacion==1){
          add = (this.modificaciones.find(m => m.idItem == mod.idItem)?.add || 0)
        }
        else if (mod.tipoModificacion==2){
          add = (this.modificaciones.find(m => m.idItem == mod.idItem)?.remove || 0)
        }
        else if (mod.tipoModificacion==3){
          add = (this.modificaciones.find(m => m.idItem == mod.idItem)?.replace || 0)
        }
        tot += (mod.uds * add)
      })
      if (!this.pricing.modificacionesCustom) this.pricing.modificacionesCustom = []
      this.pricing.modificacionesCustom.forEach(mod => {
        var add = 0
        if (mod.tipoModificacion==1){
          add = mod.precio
        }
        else if (mod.tipoModificacion==2){
          add = Math.abs(mod.precio)*-1
        }
        else if (mod.tipoModificacion==3){
          add = mod.precio
        }
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    prCat(){
      var tot = 0
      if (!this.pricing.catering) this.pricing.catering = []
      this.pricing.catering.filter(m => [4,5,6].includes(m.tipoModificacion)).forEach(mod => {
        var add = mod.precio
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    prAtenciones(){
      var tot = 0
      if (!this.pricing.catering) this.pricing.catering = []
      this.pricing.catering.filter(m => [7].includes(m.tipoModificacion)).forEach(mod => {
        var add = mod.precio
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    addMod(tipo,mod){
      if (!this.pricing.modificaciones) this.pricing.modificaciones = []
      if (this.pricing.modificaciones.filter(a => a.idItem == mod.idItem && a.tipoModificacion == tipo).length > 0) this.pricing.modificaciones.filter(a => a.idItem == mod.idItem && a.tipoModificacion == tipo)[0].uds++
      else this.$set(this.pricing.modificaciones, this.pricing.modificaciones.length, { tipoModificacion : tipo, idItem : mod.idItem, uds : 1 })
      this.$forceUpdate()
    },
    addModCustom(tipo){
      if (!this.pricing.modificacionesCustom) this.pricing.modificacionesCustom = []
      this.$set(this.pricing.modificacionesCustom, this.pricing.modificacionesCustom.length, { tipoModificacion: tipo, idItem: this.pricing.modificacionesCustom.length +1, orden: this.pricing.modificaciones?.length || 0, uds: 1, nombre: '', precio: 0 })
      this.$forceUpdate()
    },
    addCat(tipo){
      if (!this.pricing.catering) this.pricing.catering = []
      this.$set(this.pricing.catering, this.pricing.catering.length, { tipoModificacion : tipo, idItem : this.pricing.catering.length +1, uds : 1, nombre: '', precio: 0 })
      this.$forceUpdate()
    },
    removeModificacion(tipo,mod){
      if (!this.pricing.modificaciones) this.pricing.modificaciones = []
      for (var i = this.pricing.modificaciones.length - 1; i >= 0; --i) {
        if (this.pricing.modificaciones[i].tipoModificacion == tipo && this.pricing.modificaciones[i].idItem == mod) {
          this.pricing.modificaciones.splice(i,1);
          this.$forceUpdate()
          return
        }
      }
    },
    removeModificacionCustom(tipo,mod){
      if (!this.pricing.modificacionesCustom) this.pricing.modificacionesCustom = []
      for (var i = this.pricing.modificacionesCustom.length - 1; i >= 0; --i) {
        if (this.pricing.modificacionesCustom[i].tipoModificacion == tipo && this.pricing.modificacionesCustom[i].idItem == mod) {
          this.pricing.modificacionesCustom.splice(i,1);
          this.$forceUpdate()
          return
        }
      }
    },
    removeCatering(tipo,mod){
      if (!this.pricing.catering) this.pricing.catering = []
      for (var i = this.pricing.catering.length - 1; i >= 0; --i) {
        if (this.pricing.catering[i].tipoModificacion == tipo && this.pricing.catering[i].idItem == mod) {
          this.pricing.catering.splice(i,1);
          this.$forceUpdate()
          return
        }
      }
    },
    async save() {
      if (!this.pricing.nombre || this.pricing.nombre.length == 0) this.pricing.nombre = this.default.nombre;
      if (!this.$refs.form.validate()) return;
      if (!this.pricing.fecha) this.pricing.fecha = new Date(new Date().getTime() + (new Date().getTimezoneOffset()*-1)*60000).toISOString().split('T')[0]
      if (!this.pricing.pDef) this.pricing.pDef = this.prTotal()
      if (!this.pricing.pntDif) this.pricing.pntDif = 0
      this.pricing.tipo = this.tipo
      this.pricing.precioCarta = (this.cartas.find(c => c.idCarta == this.pricing.idCarta)?.precio || 0)
      this.pricing.modificaciones.forEach(mod => {
        mod.add = (this.modificaciones.find(m => m.idItem == mod.idItem)?.add || 0)
        mod.remove = (this.modificaciones.find(m => m.idItem == mod.idItem)?.remove || 0)
        mod.replace = (this.modificaciones.find(m => m.idItem == mod.idItem)?.replace || 0)
      });
      this.pricing.catering.forEach(mod => {
        mod.precio = (mod.precio || 0)
      });
      await axios({
        method: "POST",
        url: `/pricings/${String(this.pricing.idPricing ? this.pricing.idPricing : "")}`.replace(/\/+$/, ''),
        data: {
          ...this.pricing,
        },
      });
      this.pricing = { year: new Date().getFullYear() }
      this.$emit("reload");
    },
  },
};
</script>

<style scoped>
.ended-input >>> input {
    color: var(--v-info-base);
    text-align:right;
}
.blue-input >>> input {
    color: var(--v-info-base);
    /* text-align:right; */
}
.short-input >>> input {
    /* color: var(--v-info-base); */
    /* text-align:right; */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
.num >>> input::-webkit-outer-spin-button,
.num >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.num >>> input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

</style>